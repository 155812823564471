// transaction-review.service.ts
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponseModel } from "src/app/models/common.model";
import {
  Categories,
  StatementTransaction,
  TransactionFlagsForGrid,
  UpdateTransactionCategory,
} from "../../models/TransactionReviewModels/statement-transaction.model";
import { TokenStorageService } from "../TokenStorageService/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class TransactionReviewService {
  idToken: string;
  constructor(
    private http: HttpClient,
    tokenStorageService: TokenStorageService,
    @Inject("BASE_URL") private baseUrl: string
  ) {
    this.idToken = tokenStorageService.getAccessToken() || "";
  }
  apiUrl = `${this.baseUrl}api/`;

  // getTransactionType(): Observable<TransactionTypeResponse> {
  //   const headers = new HttpHeaders({
  //     Authorization: `Bearer ${this.idToken}`,
  //   });
  //   return this.http.get<TransactionTypeResponse>(`${this.apiUrl}Common/TransactionType`, { headers });
  // }

  updateTransactionCategory(
    transaction: UpdateTransactionCategory
  ): Observable<ApiResponseModel<StatementTransaction[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.put<ApiResponseModel<StatementTransaction[]>>(
      `${this.apiUrl}StatementTransaction/saveStatementTransactionByID`,
      transaction,
      { headers }
    );
  }

  // getAccountTypeData(): Observable<AccountTypes[]> {
  //   const headers = new HttpHeaders({
  //     Authorization: `Bearer ${this.idToken}`,
  //   });
  //   return this.http.get<AccountTypes[]>(`${this.apiUrl}Common/AccountType`, { headers });
  // }

  // getStatementPeriod(loanId: string, isIncomeCalculation: boolean): Observable<StatementPeriods[]> {
  //   const headers = new HttpHeaders({
  //     Authorization: `Bearer ${this.idToken}`,
  //   });
  //   return this.http.get<StatementPeriods[]>(
  //     `${this.apiUrl}StatementTransaction/GetStatementPeriods?loanId=${loanId}&incomeCalculation=${isIncomeCalculation}`,
  //     { headers }
  //   );
  // }

  getTransactionFlags(): Observable<ApiResponseModel<TransactionFlagsForGrid[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<TransactionFlagsForGrid[]>>(
      `${this.apiUrl}StatementTransaction/TransactionFlags`,
      { headers }
    );
  }

  getCategories(): Observable<ApiResponseModel<Categories[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<Categories[]>>(`${this.apiUrl}StatementTransaction/GetCategories`, {
      headers,
    });
  }

  // getAccountNumbers(loanId: string, isIncomeCalculation: boolean): Observable<AccountNumber[]> {
  //   const headers = new HttpHeaders({
  //     Authorization: `Bearer ${this.idToken}`,
  //   });
  //   return this.http.get<AccountNumber[]>(
  //     `${this.apiUrl}StatementSummary/GetDistinctStatementSummaryByLoanIdAndIncomeCalculation?loanId=${loanId}&incomeCalculation=${isIncomeCalculation}`,
  //     { headers }
  //   );
  // }

  addOrUpdateTransactionFlags(
    statementTransaction_Id: string,
    transactionFlag_Code: string | null,
    loanId: string
  ): Observable<ApiResponseModel<StatementTransaction[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const payload = {
      statementTransaction_Id: statementTransaction_Id,
      transactionFlag_Codes: transactionFlag_Code ? [transactionFlag_Code] : [], // Send an empty array if transactionFlag_Code is null
      loan_Id: loanId,
    };
    return this.http.post<ApiResponseModel<StatementTransaction[]>>(
      `${this.apiUrl}StatementTransaction/AddOrUpdateTransactionFlags`,
      payload,
      { headers }
    );
  }

  getAllStatementTransactionsByLoanId(loanId: string): Observable<ApiResponseModel<StatementTransaction[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<StatementTransaction[]>>(
      `${this.baseUrl}api/StatementTransaction/StatementTransactions/${loanId}`,
      { headers }
    );
  }
}
