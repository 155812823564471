// transaction-review.service.ts
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponseModel } from "src/app/models/common.model";
import {
  Categories,
  StatementTransaction,
  TransactionFlagsForGrid,
  UpdateTransactionCategory,
} from "../../models/TransactionReviewModels/statement-transaction.model";
import { TokenStorageService } from "../TokenStorageService/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class TransactionReviewService {
  idToken: string;
  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    @Inject("BASE_URL") private baseUrl: string
  ) {
    this.idToken = tokenStorageService.getAccessToken() || "";
  }
  apiUrl = `${this.baseUrl}api/`;

  updateTransactionCategory(
    transaction: UpdateTransactionCategory
  ): Observable<ApiResponseModel<StatementTransaction[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.put<ApiResponseModel<StatementTransaction[]>>(
      `${this.apiUrl}StatementTransaction/saveStatementTransactionByID?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      transaction,
      { headers }
    );
  }

  getTransactionFlags(): Observable<ApiResponseModel<TransactionFlagsForGrid[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<TransactionFlagsForGrid[]>>(
      `${this.apiUrl}StatementTransaction/TransactionFlags?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      { headers }
    );
  }

  getCategories(): Observable<ApiResponseModel<Categories[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<Categories[]>>(
      `${this.apiUrl}StatementTransaction/GetCategories?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      {
        headers,
      }
    );
  }

  addOrUpdateTransactionFlags(
    statementTransaction_Id: string,
    transactionFlag_Code: string | null,
    loanId: string
  ): Observable<ApiResponseModel<StatementTransaction[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const payload = {
      statementTransaction_Id: statementTransaction_Id,
      transactionFlag_Codes: transactionFlag_Code ? [transactionFlag_Code] : [], // Send an empty array if transactionFlag_Code is null
      loan_Id: loanId,
    };
    return this.http.post<ApiResponseModel<StatementTransaction[]>>(
      `${this.apiUrl}StatementTransaction/AddOrUpdateTransactionFlags?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      payload,
      { headers }
    );
  }

  getAllStatementTransactionsByLoanId(loanId: string): Observable<ApiResponseModel<StatementTransaction[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<StatementTransaction[]>>(
      `${this.baseUrl}api/StatementTransaction/StatementTransactions/${loanId}?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      { headers }
    );
  }
}
