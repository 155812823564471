import {
  ADD_LOAN_DATA_EXTRACTION,
  ADD_LOAN_DATA_EXTRACTION_FAILURE,
  ADD_LOAN_DATA_EXTRACTION_SUCCESS,
  DataExtractionHilActions,
  DELETE_RESTORE_FIELD_VALUE,
  DELETE_RESTORE_FIELD_VALUE_FAILURE,
  DELETE_RESTORE_FIELD_VALUE_SUCCESS,
  FETCH_LOAN_FIELDS,
  FETCH_LOAN_FIELDS_FAILURE,
  FETCH_LOAN_FIELDS_SUCCESS,
  GET_DOCUMENT_BY_DOCUMENT_ID,
  GET_DOCUMENT_BY_DOCUMENT_ID_FAILURE,
  GET_DOCUMENT_BY_DOCUMENT_ID_SUCCESS,
  GET_ENTITY_LIST,
  GET_ENTITY_LIST_FAILURE,
  GET_ENTITY_LIST_SUCCESS,
  GET_LOAN_FIELD_DOCUMENTS,
  GET_LOAN_FIELD_DOCUMENTS_FAILURE,
  GET_LOAN_FIELD_DOCUMENTS_SUCCESS,
  LOAD_DATA_EXTRACTION_HIL,
  LOAD_DATA_EXTRACTION_HIL_FAILURE,
  LOAD_DATA_EXTRACTION_HIL_SUCCESS,
  REVERT_TO_ORIGINAL_FIELD_DATA,
  REVERT_TO_ORIGINAL_FIELD_DATA_FAILURE,
  REVERT_TO_ORIGINAL_FIELD_DATA_SUCCESS,
  UPDATE_IS_TRUTH,
  UPDATE_IS_TRUTH_FAILURE,
  UPDATE_IS_TRUTH_SUCCESS,
  UPDATE_LOAN_DATA_EXTRACTION,
  UPDATE_LOAN_DATA_EXTRACTION_FAILURE,
  UPDATE_LOAN_DATA_EXTRACTION_SUCCESS,
  UPDATE_THE_FIELD_DATA,
  UPDATE_THE_FIELD_DATA_FAILURE,
  UPDATE_THE_FIELD_DATA_SUCCESS,
} from "./data-extraction-hil.actions";
import { DataExtractionHILStoreState } from "./data-extraction-hil.types";

const initialState: DataExtractionHILStoreState = {
  dataExtractionHIL: { errorMessage: undefined, data: undefined, loading: false },
  document: { errorMessage: undefined, data: {}, loading: false },
  updatedDataExtraction: { errorMessage: undefined, data: undefined, loading: false },
  addLoanDataExtractionSuccess: false,
  updateIsTruthSuccess: { success: false, error: false },
  deleteRestoreFieldValueResponse: { success: false, error: false },
  loanFieldsResponse: { errorMessage: undefined, data: undefined, loading: false },
  entityListResponse: { errorMessage: undefined, data: [], loading: false },
  // documentState: { errorMessage: undefined, data: undefined, loading: false },
  documentData: { errorMessage: undefined, data: undefined, loading: false },
  updateTheFieldData: { success: false, error: false },
  revertToOriginalFieldValue: { success: false, error: false },
};

let remainingDocuments;
export function dataExtractionHILReducer(
  state = initialState,
  action: DataExtractionHilActions
): DataExtractionHILStoreState {
  switch (action.type) {
    case LOAD_DATA_EXTRACTION_HIL:
      return {
        ...state,
        dataExtractionHIL: {
          ...state.dataExtractionHIL,
          loading: true,
          errorMessage: undefined,
        },
      };
    case ADD_LOAN_DATA_EXTRACTION:
      return {
        ...state,
        dataExtractionHIL: {
          ...state.dataExtractionHIL,
          loading: true,
          errorMessage: undefined,
        },
      };

    case LOAD_DATA_EXTRACTION_HIL_SUCCESS:
      return {
        ...state,
        dataExtractionHIL: {
          ...state.dataExtractionHIL,
          data: action.data,
          loading: false,
        },
      };

    case LOAD_DATA_EXTRACTION_HIL_FAILURE:
    case ADD_LOAN_DATA_EXTRACTION_FAILURE:
      return {
        ...state,
        dataExtractionHIL: {
          ...state.dataExtractionHIL,
          loading: false,
          errorMessage: action.error,
        },
        addLoanDataExtractionSuccess: false,
      };
    case ADD_LOAN_DATA_EXTRACTION_SUCCESS:
      return {
        ...state,
        dataExtractionHIL: {
          ...state.dataExtractionHIL,
          loading: false,
          errorMessage: undefined,
        },
        addLoanDataExtractionSuccess: true,
      };

    case DELETE_RESTORE_FIELD_VALUE:
      return {
        ...state,
        deleteRestoreFieldValueResponse: {
          success: false,
          error: false,
        },
      };
    case DELETE_RESTORE_FIELD_VALUE_SUCCESS:
      return {
        ...state,
        deleteRestoreFieldValueResponse: {
          success: true,
          error: false,
        },
      };
    case DELETE_RESTORE_FIELD_VALUE_FAILURE:
      return {
        ...state,
        deleteRestoreFieldValueResponse: {
          success: false,
          error: true,
        },
      };

    case FETCH_LOAN_FIELDS:
      return {
        ...state,
        loanFieldsResponse: {
          ...state.loanFieldsResponse,
          loading: true,
          errorMessage: undefined,
        },
      };

    case FETCH_LOAN_FIELDS_SUCCESS:
      return {
        ...state,
        loanFieldsResponse: {
          ...state.loanFieldsResponse,
          loading: false,
          data: action.response,
          errorMessage: undefined,
        },
      };
    case FETCH_LOAN_FIELDS_FAILURE:
      return {
        ...state,
        loanFieldsResponse: {
          ...state.loanFieldsResponse,
          loading: false,
          errorMessage: action.error,
        },
      };
    case GET_ENTITY_LIST:
      return {
        ...state,
        entityListResponse: {
          ...state.entityListResponse,
          loading: true,
          errorMessage: undefined,
        },
      };

    case GET_ENTITY_LIST_SUCCESS:
      return {
        ...state,
        entityListResponse: {
          ...state.entityListResponse,
          loading: false,
          data: action.response,
          errorMessage: undefined,
        },
      };

    case GET_ENTITY_LIST_FAILURE:
      return {
        ...state,
        entityListResponse: {
          ...state.entityListResponse,
          loading: false,
          errorMessage: action.error,
        },
      };

    case GET_DOCUMENT_BY_DOCUMENT_ID:
      return {
        ...state,
        document: {
          ...state.document,
          errorMessage: undefined,
          loading: true,
        },
      };

    case GET_DOCUMENT_BY_DOCUMENT_ID_SUCCESS:
      return {
        ...state,
        document: {
          errorMessage: undefined,
          data: {
            ...state.document.data,
            [action.documentId]: action.data,
          },
          loading: false,
        },
      };
    case GET_LOAN_FIELD_DOCUMENTS:
      return {
        ...state,
        documentData: {
          ...state.documentData,
          loading: true,
          errorMessage: undefined,
        },
      };
    case GET_LOAN_FIELD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentData: {
          ...state.documentData,
          data: action.response,
          loading: false,
          errorMessage: undefined,
        },
      };
    case GET_LOAN_FIELD_DOCUMENTS_FAILURE:
      return {
        ...state,
        documentData: {
          ...state.documentData,
          loading: false,
          errorMessage: action.error,
        },
      };
    case GET_DOCUMENT_BY_DOCUMENT_ID_FAILURE:
      remainingDocuments = { ...state.document.data };
      delete remainingDocuments[action.documentId];
      return {
        ...state,
        document: {
          errorMessage: action.message,
          data: remainingDocuments,
          loading: false,
        },
      };
    case UPDATE_LOAN_DATA_EXTRACTION:
      return {
        ...state,
        updatedDataExtraction: {
          ...state.dataExtractionHIL,
          loading: true,
          errorMessage: undefined,
        },
      };

    case UPDATE_LOAN_DATA_EXTRACTION_SUCCESS:
      return {
        ...state,
        updatedDataExtraction: {
          ...state.dataExtractionHIL,
          loading: false,
          errorMessage: undefined,
        },
      };

    case UPDATE_LOAN_DATA_EXTRACTION_FAILURE:
      return {
        ...state,
        updatedDataExtraction: {
          ...state.dataExtractionHIL,
          loading: false,
          errorMessage: action.error,
        },
      };

    case UPDATE_IS_TRUTH:
      return {
        ...state,
        updateIsTruthSuccess: {
          success: false,
          error: false,
        },
      };

    case UPDATE_IS_TRUTH_SUCCESS:
      return {
        ...state,
        updateIsTruthSuccess: {
          success: true,
          error: false,
        },
      };

    case UPDATE_IS_TRUTH_FAILURE:
      return {
        ...state,
        updateIsTruthSuccess: {
          success: false,
          error: true,
        },
      };

    case UPDATE_THE_FIELD_DATA:
      return {
        ...state,
        updateTheFieldData: {
          success: false,
          error: false,
        },
      };

    case UPDATE_THE_FIELD_DATA_SUCCESS:
      return {
        ...state,
        updateTheFieldData: {
          success: true,
          error: false,
        },
      };

    case UPDATE_THE_FIELD_DATA_FAILURE:
      return {
        ...state,
        updateTheFieldData: { success: false, error: true },
      };

    case REVERT_TO_ORIGINAL_FIELD_DATA:
      return {
        ...state,
        revertToOriginalFieldValue: {
          success: false,
          error: false,
        },
      };

    case REVERT_TO_ORIGINAL_FIELD_DATA_SUCCESS:
      return {
        ...state,
        revertToOriginalFieldValue: {
          success: true,
          error: false,
        },
      };

    case REVERT_TO_ORIGINAL_FIELD_DATA_FAILURE:
      return {
        ...state,
        revertToOriginalFieldValue: { success: false, error: true },
      };

    default:
      return state;
  }
}
