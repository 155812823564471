import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { BankStatementExtractionService } from "src/app/service/BankStatementExtractionService/bank-statement-extraction.service";
import { CommonService } from "src/app/service/CommonService/common.service";
import { IncomeCalculationServiceService } from "src/app/service/IncomeCalculation/income-calculation.service";
import {
  CalculateIncome,
  CalculateIncomeFailure,
  CalculateIncomeSuccess,
  GetAccountDetails,
  GetAccountDetailsFailure,
  GetAccountDetailsSuccess,
  GetCategoryByLoanId,
  GetCategoryByLoanIdFailure,
  GetCategoryByLoanIdSuccess,
  GetEmployeeCount,
  GetEmployeeCountFailure,
  GetEmployeeCountSuccess,
  GetExportLoan,
  GetExportLoanFailure,
  GetExportLoanSuccess,
  GetIncomeDetails,
  GetIncomeDetailsFailure,
  GetIncomeDetailsSuccess,
  GetLoanHeaderDetailsById,
  GetLoanHeaderDetailsByIdFailure,
  GetLoanHeaderDetailsByIdSuccess,
  GetTypeOfBusiness,
  GetTypeOfBusinessFailure,
  GetTypeOfBusinessSuccess,
  RecalculateThreshold,
  RecalculateThresholdFailure,
  RecalculateThresholdSuccess,
  UpdateAccount,
  UpdateAccountFailure,
  UpdateAccountStatus,
  UpdateAccountStatusFailure,
  UpdateAccountStatusSuccess,
  UpdateAccountSuccess,
  UpdateCategory,
  UpdateCategoryFailure,
  UpdateCategorySuccess,
} from "./incomeCalculation.actions";

@Injectable()
export class IncomeCalculationEffects {
  constructor(
    private actions$: Actions,
    private incomeCalculationService: IncomeCalculationServiceService,
    private bankStatementExtractionService: BankStatementExtractionService,
    private commonService: CommonService
  ) {}

  GetExportLoan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetExportLoan),
      mergeMap((action) =>
        this.incomeCalculationService.exportToExcel(action.LoanId, action.loanNumber).pipe(
          map(() => GetExportLoanSuccess({ data: "success" })),
          catchError((error) => of(GetExportLoanFailure({ message: error })))
        )
      )
    )
  );
  getAccountDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAccountDetails),
      mergeMap((action) =>
        this.incomeCalculationService.getDistinctStatementSummary(action.loanId).pipe(
          map((response) => {
            if (response.success) {
              return GetAccountDetailsSuccess({ data: response.data });
            } else {
              return GetAccountDetailsFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) =>
            of(
              GetAccountDetailsFailure({
                message: error,
                errors: [],
              })
            )
          )
        )
      )
    )
  );

  getIncomeDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetIncomeDetails),
      mergeMap((action) =>
        this.incomeCalculationService.getAllIncomeDetails(action.loanId).pipe(
          map((response) => {
            if (response.success) {
              return GetIncomeDetailsSuccess({ data: response.data });
            } else {
              return GetIncomeDetailsFailure({ errors: [], message: response.message });
            }
          }),
          catchError((error) =>
            of(
              GetIncomeDetailsFailure({
                message: error,
                errors: [],
              })
            )
          )
        )
      )
    )
  );

  getLoanHeaderDetailsById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetLoanHeaderDetailsById),
      mergeMap((action) =>
        this.bankStatementExtractionService.getLoanHeaderDetailsById(action.loanId).pipe(
          map((response) => {
            if (response.success) {
              return GetLoanHeaderDetailsByIdSuccess({ data: response.data });
            } else {
              return GetLoanHeaderDetailsByIdFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) =>
            of(
              GetLoanHeaderDetailsByIdFailure({
                message: error,
                errors: [],
              })
            )
          )
        )
      )
    )
  );

  getTypeOfBusiness$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetTypeOfBusiness),
      mergeMap(() =>
        this.commonService.getTypeOfBusiness().pipe(
          map((response) => {
            if (response.success) {
              return GetTypeOfBusinessSuccess({ data: response.data });
            } else {
              return GetTypeOfBusinessFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) =>
            of(
              GetTypeOfBusinessFailure({
                message: error,
                errors: [],
              })
            )
          )
        )
      )
    )
  );

  getEmployeeCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetEmployeeCount),
      mergeMap(() =>
        this.commonService.getEmployeeCount().pipe(
          map((response) => {
            if (response.success) {
              return GetEmployeeCountSuccess({ data: response.data });
            } else {
              return GetEmployeeCountFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) =>
            of(
              GetEmployeeCountFailure({
                message: error,
                errors: [],
              })
            )
          )
        )
      )
    )
  );

  calculateIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalculateIncome),
      mergeMap((action) =>
        this.incomeCalculationService.CalculateIncome(action.calculateIncomeBody).pipe(
          map((response) => {
            if (response.success) {
              return CalculateIncomeSuccess({ data: response.data });
            } else {
              return CalculateIncomeFailure({ errors: response.message, message: response.message });
            }
          }),
          catchError((error) =>
            of(
              CalculateIncomeFailure({
                message: error,
                errors: error,
              })
            )
          )
        )
      )
    )
  );

  GetCategoryByLoanId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCategoryByLoanId),
      mergeMap((action) =>
        this.incomeCalculationService.getTransactionCategories(action.loanId).pipe(
          map((response) => {
            if (response.success) {
              return GetCategoryByLoanIdSuccess({ data: response.data });
            } else {
              return GetCategoryByLoanIdFailure({ errors: [], message: response.message });
            }
          }),
          catchError((error) =>
            of(
              GetCategoryByLoanIdFailure({
                message: error,
                errors: [],
              })
            )
          )
        )
      )
    )
  );

  reCalculateThreshold$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecalculateThreshold),
      mergeMap((action) =>
        this.incomeCalculationService.updateThreshold(action.loanId, action.thresholdFactor).pipe(
          map((response) => {
            if (response.success) {
              return RecalculateThresholdSuccess({ data: response.data });
            } else {
              return RecalculateThresholdFailure({ errors: response.message, message: response.message });
            }
          }),
          catchError((error) =>
            of(
              RecalculateThresholdFailure({
                message: error,
                errors: error,
              })
            )
          )
        )
      )
    )
  );

  UpdateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateAccount),
      mergeMap((action) =>
        this.incomeCalculationService.updateStatementByAccountNumber(action.accountId, action.isSelected).pipe(
          map((response) => {
            if (response.success) {
              return UpdateAccountSuccess({ data: response.message });
            } else {
              return UpdateAccountFailure({ errors: response.message, message: response.message });
            }
          }),
          catchError((error) =>
            of(
              UpdateAccountFailure({
                message: error,
                errors: error,
              })
            )
          )
        )
      )
    )
  );

  UpdateAccountStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateAccountStatus),
      mergeMap((action) =>
        this.incomeCalculationService.saveAccountStatus(action.data).pipe(
          map((response) => {
            if (response.success) {
              return UpdateAccountStatusSuccess({ data: response.message });
            } else {
              return UpdateAccountStatusFailure({ errors: response.message, message: response.message });
            }
          }),
          catchError((error) =>
            of(
              UpdateAccountStatusFailure({
                message: error,
                errors: error,
              })
            )
          )
        )
      )
    )
  );

  UpdateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCategory),
      mergeMap((action) =>
        this.incomeCalculationService
          .updateStatementTransactionBucket(action.loanId, action.isQualified, action.categoryCode)
          .pipe(
            map((response) => {
              if (response.success) {
                return UpdateCategorySuccess({ data: response.message });
              } else {
                return UpdateCategoryFailure({ errors: response.message, message: response.message });
              }
            }),
            catchError((error) =>
              of(
                UpdateCategoryFailure({
                  message: error,
                  errors: error,
                })
              )
            )
          )
      )
    )
  );
}
