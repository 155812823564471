import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponseModel } from "src/app/models/common.model";
import { StatementTransaction } from "src/app/models/TransactionReviewModels/statement-transaction.model";
import {
  CombinedTransactions,
  IncludeExcludeLoanTransaction,
  ReallocateTransactionRequest,
  RestoreTransactionRequest,
  SwapSignageTransactionRequest,
  TransactionDetails,
  TransactionStatementPeriodDetails,
} from "src/app/store/transaction/transaction.types";
import { TokenStorageService } from "../TokenStorageService/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class StatementTransactionServiceService {
  idToken: string;
  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    @Inject("BASE_URL") private baseUrl: string
  ) {
    this.idToken = tokenStorageService.getAccessToken() || "";
  }

  getTransactionDetails(loanId: string, summaryId: string, isDeleted?: boolean) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    let apiUrl =
      this.baseUrl +
      `api/StatementTransaction/Details/${loanId}/${summaryId}?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`;
    if (isDeleted) {
      apiUrl =
        this.baseUrl +
        `api/StatementTransaction/Details/${loanId}/${summaryId}/true?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`;
    }
    return this.http.get<ApiResponseModel<TransactionDetails[]>>(apiUrl, { headers });
  }

  getStatementSummaryDetails(loanId: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<TransactionStatementPeriodDetails[]>>(
      this.baseUrl +
        `api/StatementSummary/Details/${loanId}?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      { headers }
    );
  }

  saveValidatetransactions(transactions: TransactionDetails[], flag: boolean) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
      "Content-Type": "application/json",
    });
    const StatementTransactions: TransactionDetails[] = transactions;
    return this.http.post(
      this.baseUrl +
        `api/StatementSummary/SaveValidateStatementTransaction?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}&isValidated=${flag}`,
      StatementTransactions,
      { headers, responseType: "text" }
    );
  }

  saveOrValidateTransactionDetails(
    statementTransactions: TransactionDetails[],
    isValidated: boolean,
    loanId: string,
    summaryID: string
  ) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
      "Content-Type": "application/json",
    });
    const payload = {
      statementTransactions,
      isValidated,
      loanId,
      summaryID,
    };
    return this.http.post<ApiResponseModel<CombinedTransactions>>(
      this.baseUrl +
        `api/StatementSummary/SaveValidateStatementTransaction?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      payload,
      { headers }
    );
  }

  reValidateSummary(statementId: string, isValidate: boolean) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.put<ApiResponseModel<string>>(
      this.baseUrl +
        `api/StatementSummary/ReValidateSummary?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      {
        statementId,
        isValidate,
        headers,
      }
    );
  }

  processLoanTransactionFlags(loanId: string) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${this.idToken}`, "Content-Type": "application/json" });
    return this.http.post<ApiResponseModel<null>>(
      `${this.baseUrl}api/BankStatementExtraction/StatementSummary/ProcessTransactionFlags/${loanId}?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      {},
      { headers }
    );
  }

  processLoanCategory(loanId: string) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${this.idToken}`, "Content-Type": "application/json" });
    return this.http.post<ApiResponseModel<null>>(
      `${this.baseUrl}api/BankStatementExtraction/StatementSummary/ProcessCategory/${loanId}?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      {},
      { headers }
    );
  }

  includeExcludeForIncomeCalculation(
    payload: IncludeExcludeLoanTransaction
  ): Observable<ApiResponseModel<StatementTransaction[]>> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${this.idToken}`, "Content-Type": "application/json" });
    return this.http.post<ApiResponseModel<StatementTransaction[]>>(
      `${this.baseUrl}api/StatementTransaction/IncludeExcludeForIncomeCalculation?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      payload,
      { headers }
    );
  }

  resetIncludeExcludeForIncomeCalculation(
    payload: IncludeExcludeLoanTransaction
  ): Observable<ApiResponseModel<StatementTransaction[]>> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${this.idToken}`, "Content-Type": "application/json" });
    return this.http.post<ApiResponseModel<StatementTransaction[]>>(
      `${this.baseUrl}api/StatementTransaction/ResetIncludeExcludeForIncomeCalculation?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      payload,
      { headers }
    );
  }
  reallocationTransaction(reallocateTransactionRequest: ReallocateTransactionRequest) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
      "Content-Type": "application/json",
    });
    return this.http.post<ApiResponseModel<CombinedTransactions>>(
      this.baseUrl +
        `api/StatementSummary/ReallocateTransaction?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      reallocateTransactionRequest,
      { headers }
    );
  }

  swapSignageTransaction(swapSignageTransactionRequest: SwapSignageTransactionRequest) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
      "Content-Type": "application/json",
    });
    return this.http.post<ApiResponseModel<CombinedTransactions>>(
      this.baseUrl +
        `api/statementTransaction/PositiveNegativeSignChange?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      swapSignageTransactionRequest,
      { headers }
    );
  }

  restoreTransaction(restoreTransactionRequest: RestoreTransactionRequest) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
      "Content-Type": "application/json",
    });
    return this.http.post<ApiResponseModel<CombinedTransactions>>(
      this.baseUrl +
        `api/statementTransaction/Restore?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      restoreTransactionRequest,
      { headers }
    );
  }
}
