import {
  CALCULATE_INCOME,
  CALCULATE_INCOME_FAILURE,
  CALCULATE_INCOME_SUCCESS,
  GET_ACCOUNT_DETAILS,
  GET_ACCOUNT_DETAILS_FAILURE,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_CATEGORY_BY_LOANID,
  GET_CATEGORY_BY_LOANID_FAILURE,
  GET_CATEGORY_BY_LOANID_SUCCESS,
  GET_EMPLOYEE_COUNT,
  GET_EMPLOYEE_COUNT_FAILURE,
  GET_EMPLOYEE_COUNT_SUCCESS,
  GET_EXPORT_LOANS,
  GET_EXPORT_LOANS_FAILURE,
  GET_EXPORT_LOANS_SUCCESS,
  GET_INCOME_DETAILS,
  GET_INCOME_DETAILS_FAILURE,
  GET_INCOME_DETAILS_SUCCESS,
  GET_LOAN_HEADER_DETAILS_BY_ID,
  GET_LOAN_HEADER_DETAILS_BY_ID_FAILURE,
  GET_LOAN_HEADER_DETAILS_BY_ID_SUCCESS,
  GET_TYPE_OF_BUSINESS,
  GET_TYPE_OF_BUSINESS_FAILURE,
  GET_TYPE_OF_BUSINESS_SUCCESS,
  IncomeCalculationActions,
  RECALCULATE_THRESHOLD,
  RECALCULATE_THRESHOLD_FAILURE,
  RECALCULATE_THRESHOLD_SUCCESS,
  RESET_GET_INCOME_DETAILS,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_STATUS,
  UPDATE_ACCOUNT_STATUS_FAILURE,
  UPDATE_ACCOUNT_STATUS_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_SUCCESS,
} from "./incomeCalculation.actions";
import { IncomeCalculationState } from "./incomeCalculation.types";

const initialState: IncomeCalculationState = {
  exportExcel: { data: "", errorMessage: undefined },
  accountDetails: { data: [], errorMessage: undefined },
  incomeDetails: { data: undefined, errorMessage: undefined },
  loanDetails: { data: undefined, errorMessage: undefined },
  typeOfBusiness: { data: [], errorMessage: undefined },
  employeeCount: { data: [], errorMessage: undefined },
  calculateIncomeResponse: { data: true, errorMessage: undefined },
  transactionCategory: { data: [], errorMessage: undefined },
  recalculateThresholdResponse: { data: "", errorMessage: undefined },
  updateAccountResponse: { data: "", errorMessage: undefined },
  updateCategoryResponse: { data: "", errorMessage: undefined },
  updateAccountStatusResponse: { data: "", errorMessage: undefined },
};

export function IncomeCalculationReducer(
  state = initialState,
  action: IncomeCalculationActions
): IncomeCalculationState {
  switch (action.type) {
    case GET_EXPORT_LOANS:
    case GET_ACCOUNT_DETAILS:
    case GET_INCOME_DETAILS:
    case GET_LOAN_HEADER_DETAILS_BY_ID:
    case GET_TYPE_OF_BUSINESS:
    case GET_EMPLOYEE_COUNT:
    case CALCULATE_INCOME:
    case GET_CATEGORY_BY_LOANID:
    case RECALCULATE_THRESHOLD:
    case UPDATE_ACCOUNT:
    case UPDATE_CATEGORY:
    case UPDATE_ACCOUNT_STATUS:
      return state;

    case GET_EXPORT_LOANS_SUCCESS: {
      return {
        ...state,
        exportExcel: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_EXPORT_LOANS_FAILURE: {
      return {
        ...state,
        exportExcel: {
          errorMessage: action.message,
          data: undefined,
        },
      };
    }
    case GET_ACCOUNT_DETAILS_SUCCESS: {
      return {
        ...state,
        accountDetails: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_ACCOUNT_DETAILS_FAILURE: {
      return {
        ...state,
        accountDetails: {
          errorMessage: action.message,
          data: [],
        },
      };
    }
    case GET_INCOME_DETAILS_SUCCESS: {
      return {
        ...state,
        incomeDetails: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_INCOME_DETAILS_FAILURE: {
      return {
        ...state,
        accountDetails: {
          errorMessage: action.message,
          data: [],
        },
      };
    }
    case RESET_GET_INCOME_DETAILS: {
      return {
        ...state,
        accountDetails: {
          errorMessage: undefined,
          data: [],
        },
      };
    }
    case GET_LOAN_HEADER_DETAILS_BY_ID_SUCCESS: {
      return {
        ...state,
        loanDetails: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_LOAN_HEADER_DETAILS_BY_ID_FAILURE: {
      return {
        ...state,
        loanDetails: {
          errorMessage: action.message,
          data: undefined,
        },
      };
    }
    case GET_TYPE_OF_BUSINESS_SUCCESS: {
      return {
        ...state,
        typeOfBusiness: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_TYPE_OF_BUSINESS_FAILURE: {
      return {
        ...state,
        typeOfBusiness: {
          errorMessage: action.message,
          data: [],
        },
      };
    }
    case GET_EMPLOYEE_COUNT_SUCCESS: {
      return {
        ...state,
        employeeCount: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_EMPLOYEE_COUNT_FAILURE: {
      return {
        ...state,
        employeeCount: {
          errorMessage: action.message,
          data: [],
        },
      };
    }
    case CALCULATE_INCOME_SUCCESS: {
      return {
        ...state,
        calculateIncomeResponse: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case CALCULATE_INCOME_FAILURE: {
      return {
        ...state,
        calculateIncomeResponse: {
          errorMessage: action.message,
          data: false,
        },
      };
    }
    case GET_CATEGORY_BY_LOANID_SUCCESS: {
      return {
        ...state,
        transactionCategory: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_CATEGORY_BY_LOANID_FAILURE: {
      return {
        ...state,
        accountDetails: {
          errorMessage: action.message,
          data: [],
        },
      };
    }
    case RECALCULATE_THRESHOLD_SUCCESS: {
      return {
        ...state,
        recalculateThresholdResponse: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case RECALCULATE_THRESHOLD_FAILURE: {
      return {
        ...state,
        recalculateThresholdResponse: {
          errorMessage: action.message,
          data: "",
        },
      };
    }

    case UPDATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        updateAccountResponse: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case UPDATE_ACCOUNT_FAILURE: {
      return {
        ...state,
        updateAccountResponse: {
          errorMessage: action.message,
          data: "",
        },
      };
    }
    case UPDATE_ACCOUNT_STATUS_SUCCESS: {
      return {
        ...state,
        updateAccountStatusResponse: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case UPDATE_ACCOUNT_STATUS_FAILURE: {
      return {
        ...state,
        updateAccountStatusResponse: {
          errorMessage: action.message,
          data: "",
        },
      };
    }

    case UPDATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        updateCategoryResponse: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case UPDATE_CATEGORY_FAILURE: {
      return {
        ...state,
        updateCategoryResponse: {
          errorMessage: action.message,
          data: "",
        },
      };
    }

    default:
      return state;
  }
}
