/* eslint-disable */
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ToastrService } from "ngx-toastr";
import { catchError, map, mergeMap, of } from "rxjs";
import { RuleEngineService } from "src/app/service/RuleEngineService/rule-engine.service";
import {
  AddRule,
  GetAddedRuleFailure,
  GetAddedRuleSuccess,
  GetApplicableLoanTypes,
  GetApplicableLoanTypesFailure,
  GetApplicableLoanTypesSuccess,
  GetDocumentTypes,
  GetDocumentTypesFailure,
  GetDocumentTypesSuccess,
  GetDocumentVersionFieldList,
  GetDocumentVersionFieldListFailure,
  GetDocumentVersionFieldListSuccess,
  getLOSFieldList,
  getLOSFieldListFailure,
  getLOSFieldListSuccess,
  GetProjectsByCustomerId,
  GetProjectsByCustomerIdFailure,
  GetProjectsByCustomerIdSuccess,
  GetRuleById,
  GetRuleByIdSuccess,
  GetRulesList,
  GetRulesListFailure,
  GetRulesListSuccess,
  GetUpdatedRuleSuccess,
  UpdateRule,
} from "./rule-engine.actions";

@Injectable()
export class RuleEngineEffects {
  constructor(
    private actions$: Actions,
    private ruleEngineService: RuleEngineService,
    private toaster: ToastrService,
  ) {}

  AddRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddRule),
      mergeMap((action) =>
        this.ruleEngineService.createRule(action.rule).pipe(
          map((response) => {
            this.toaster.success(
              "Rule Created Successfully.",
              'Success',
              {
                timeOut: 2000,
                progressBar: true,
                positionClass: 'toast-top-center'
              }
            );
            return GetAddedRuleSuccess({ rule: response });
          }),
          catchError((error) => {
            this.toaster.error(
              "Error Creating Rule",
              'Error',
              {
                timeOut: 2000,
                progressBar: true,
                positionClass: 'toast-top-center'
              }
            );
            return of(GetAddedRuleFailure({ rule: undefined, errorMessage: "Error creating rule" }));
          })
        )
      )
    )
  );

  UpdateRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateRule),
      mergeMap((action) =>
        this.ruleEngineService.updateRule(action.ruleId, action.rule).pipe(
          map((response) => {
            return GetUpdatedRuleSuccess({ rule: {...response, id: parseInt(action.ruleId)} });
          })
        )
      )
    )
  );

  GetRuleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetRuleById),
      mergeMap((action) =>
        this.ruleEngineService.getRuleById(action.ruleId, action.projectId).pipe(
          map((response) => {
            return GetRuleByIdSuccess({ rules: response.rules });
          })
        )
      )
    )
  );

  GetLOSFieldList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLOSFieldList),
      mergeMap((action) =>
        this.ruleEngineService.getLosFieldList(action.projectId).pipe(
          map((response) => {
            try {
              if (response.success) {
                return getLOSFieldListSuccess({ LOSFieldList: response.data });
              } else {
                return getLOSFieldListFailure({ LOSFieldList: [] });
              }
            } catch(err) {
              return getLOSFieldListFailure({ LOSFieldList: [] });
            }
          }),
          catchError((error) =>
            of(getLOSFieldListFailure({ LOSFieldList: [] }))
          )
        )
      )
    )
  );

  GetRulesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetRulesList),
      mergeMap((action) =>
        this.ruleEngineService.getRules(action.ruleFilters).pipe(
          map((response) => {
            try {
              if (response.rules && response.rules.length > 0) {
                return GetRulesListSuccess({ rulesList: response.rules, isFreshFetch: action.isFreshFetch });
              } else {
                return GetRulesListFailure({ rulesList: [], isFreshFetch: action.isFreshFetch });
              }
            } catch (err) {
              return GetRulesListFailure({ rulesList: [], isFreshFetch: action.isFreshFetch });
            }
          }),
          catchError((error) =>
            of(GetRulesListFailure({ rulesList: [], isFreshFetch: action.isFreshFetch}))
          )
        )
      )
    )
  );

  GetDocumentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetDocumentTypes),
      mergeMap((action) =>
        this.ruleEngineService.getDocumentTypes(action.projectId, action.documentType).pipe(
          map((response) => {
            try {
              if (response.success && response.data[0].tableData.SYSRD_Document_Types) {
                return GetDocumentTypesSuccess({ documentTypes: response.data[0].tableData.SYSRD_Document_Types });
              } else {
                return GetDocumentTypesFailure({ documentTypes: [] });
              }
            } catch(err) {
              return GetDocumentTypesFailure({ documentTypes: [] });
            }
          }),
          catchError((error) =>
            of(GetDocumentTypesFailure({ documentTypes: [] }))
          )
        )
      )
    )
  );

  GetApplicableLoanTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetApplicableLoanTypes),
      mergeMap((action) =>
        this.ruleEngineService.getDocumentTypes(action.projectId, action.documentType).pipe(
          map((response) => {
            try {
              if (response.success && response.data[0].tableData.RD_Loan_Types) {
                return GetApplicableLoanTypesSuccess({ applicableLoanTypes: response.data[0].tableData.RD_Loan_Types });
              } else {
                return GetApplicableLoanTypesFailure({ applicableLoanTypes: [] });
              }
            } catch(err) {
              return GetApplicableLoanTypesFailure({ applicableLoanTypes: [] });
            }
          }),
          catchError((error) =>
            of(GetApplicableLoanTypesFailure({ applicableLoanTypes: [] }))
          )
        )
      )
    )
  );

  GetProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetProjectsByCustomerId),
      mergeMap((action) =>
        this.ruleEngineService.getProjectsByCustomerId(action.customerId).pipe(
          map((response) => {
            try {
              if (response.success && response.projects) {
                return GetProjectsByCustomerIdSuccess({ projects: response.projects });
              } else {
                return GetProjectsByCustomerIdFailure({ projects: [] });
              }
            } catch(err) {
              return GetProjectsByCustomerIdFailure({ projects: [] });
            }
          }),
          catchError((error) =>
            of(GetProjectsByCustomerIdFailure({ projects: [] }))
          )
        )
      )
    )
  );

  GetDocumentVersionFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetDocumentVersionFieldList),
      mergeMap((action) =>
        this.ruleEngineService.getDocumentVersionFieldList(action.documentCode, action.projectId).pipe(
          map((response) => {
            try {
              if (response.success) {
                return GetDocumentVersionFieldListSuccess({ documentVersionFieldList: response.data });
              } else {
                return GetDocumentVersionFieldListFailure({ documentVersionFieldList: [] });
              }
            } catch(err) {
              return GetDocumentVersionFieldListFailure({ documentVersionFieldList: [] });
            }
          }),
          catchError((error) =>
            of(GetDocumentVersionFieldListFailure({ documentVersionFieldList: [] }))
          )
        )
      )
    )
  );
  
}

