import {
  CLEAR_SAVE_VALIDATE_STATEMENT_TRANSACTION,
  GET_TRANSACTION_DETAILS,
  GET_TRANSACTION_DETAILS_FAILURE,
  GET_TRANSACTION_DETAILS_SUCCESS,
  GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_FAILURE,
  GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_SUCCESS,
  PROCESS_CATEGORIES,
  PROCESS_CATEGORIES_FAILURE,
  PROCESS_CATEGORIES_SUCCESS,
  PROCESS_TRANSACTION_FLAG,
  PROCESS_TRANSACTION_FLAG_FAILURE,
  PROCESS_TRANSACTION_FLAG_SUCCESS,
  REALLOCATE_TRANSACTION_FAILURE,
  REALLOCATE_TRANSACTION_SUCCESS,
  RESET_TRANSACTIONSCATEGORY_INFO,
  RESET_TRANSACTIONSFLAG_INFO,
  RESTORE_TRANSACTION,
  RESTORE_TRANSACTION_FAILURE,
  RESTORE_TRANSACTION_SUCCESS,
  REVALIDATE_SUMMARY,
  REVALIDATE_SUMMARY_FAILURE,
  REVALIDATE_SUMMARY_SUCCESS,
  SAVE_VALIDATE_STATEMENT_TRANSACTION_FAILURE,
  SAVE_VALIDATE_STATEMENT_TRANSACTION_SUCCESS,
  SWAP_SIGNAGE_TRANSACTION,
  SWAP_SIGNAGE_TRANSACTION_FAILURE,
  SWAP_SIGNAGE_TRANSACTION_SUCCESS,
  TransactionActions,
} from "./transaction.actions";
import { StatementTransactionState } from "./transaction.types";

const initialState: StatementTransactionState = {
  transactionDetail: { data: { data: [], deletedData: [] }, errorMessage: undefined, loading: false },
  transactionStatementPeriodDetails: { data: [], errorMessage: undefined },
  reValidateStatement: { data: "", errorMessage: undefined },
  processTransactionFlag: { data: undefined, errorMessage: undefined },
  processCategories: { data: undefined, errorMessage: undefined },
  saveOrValidateTransaction: { data: undefined, errorMessage: undefined },
  reallocateTransaction: { data: undefined, errorMessage: undefined },
  swapnSignageTransaction: { data: undefined, errorMessage: undefined },
  restoreTransaction: { data: undefined, errorMessage: undefined },
};

export function transactionReducer(state = initialState, action: TransactionActions): StatementTransactionState {
  switch (action.type) {
    case REVALIDATE_SUMMARY:
    case PROCESS_TRANSACTION_FLAG:
    case PROCESS_CATEGORIES:
    case SWAP_SIGNAGE_TRANSACTION:
    case RESTORE_TRANSACTION:
      return state;

    case GET_TRANSACTION_DETAILS: {
      return {
        ...state,
        transactionDetail: {
          errorMessage: undefined,
          data: { data: [], deletedData: [] },
          loading: true,
        },
      };
    }

    case GET_TRANSACTION_DETAILS_SUCCESS: {
      return {
        ...state,
        transactionDetail: {
          errorMessage: undefined,
          data: { data: action.data, deletedData: action.deletedTransactions },
          loading: false,
        },
      };
    }
    case GET_TRANSACTION_DETAILS_FAILURE: {
      return {
        ...state,
        transactionDetail: {
          errorMessage: action.message,
          data: { data: [], deletedData: [] },
          loading: false,
        },
      };
    }
    case GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_SUCCESS: {
      return {
        ...state,
        transactionStatementPeriodDetails: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_FAILURE: {
      return {
        ...state,
        transactionStatementPeriodDetails: {
          errorMessage: action.message,
          data: [],
        },
      };
    }

    case SAVE_VALIDATE_STATEMENT_TRANSACTION_SUCCESS: {
      return {
        ...state,
        saveOrValidateTransaction: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }

    case SAVE_VALIDATE_STATEMENT_TRANSACTION_FAILURE: {
      return {
        ...state,
        saveOrValidateTransaction: {
          errorMessage: action.message,
          data: undefined,
        },
      };
    }

    case CLEAR_SAVE_VALIDATE_STATEMENT_TRANSACTION: {
      return {
        ...state,
        saveOrValidateTransaction: {
          errorMessage: undefined,
          data: undefined,
        },
      };
    }

    case REVALIDATE_SUMMARY_SUCCESS: {
      return {
        ...state,
        reValidateStatement: {
          data: action.message,
          errorMessage: undefined,
        },
      };
    }
    case REVALIDATE_SUMMARY_FAILURE: {
      return {
        ...state,
        reValidateStatement: {
          data: "",
          errorMessage: action.message,
        },
      };
    }
    case PROCESS_TRANSACTION_FLAG_SUCCESS: {
      return {
        ...state,
        processTransactionFlag: {
          data: "success",
          errorMessage: undefined,
        },
      };
    }

    case RESET_TRANSACTIONSFLAG_INFO: {
      return {
        ...state,
        processTransactionFlag: {
          data: undefined,
          errorMessage: undefined,
        },
      };
    }
    case RESET_TRANSACTIONSCATEGORY_INFO: {
      return {
        ...state,
        processCategories: {
          data: undefined,
          errorMessage: undefined,
        },
      };
    }

    case PROCESS_TRANSACTION_FLAG_FAILURE: {
      return {
        ...state,
        processTransactionFlag: {
          data: undefined,
          errorMessage: action.message,
        },
      };
    }
    case PROCESS_CATEGORIES_SUCCESS: {
      return {
        ...state,
        processCategories: {
          data: "success",
          errorMessage: undefined,
        },
      };
    }
    case PROCESS_CATEGORIES_FAILURE: {
      return {
        ...state,
        processCategories: {
          data: "success",
          errorMessage: action.message,
        },
      };
    }
    case REALLOCATE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        reallocateTransaction: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }

    case REALLOCATE_TRANSACTION_FAILURE: {
      return {
        ...state,
        reallocateTransaction: {
          errorMessage: action.message,
          data: undefined,
        },
      };
    }

    case SWAP_SIGNAGE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        swapnSignageTransaction: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }

    case SWAP_SIGNAGE_TRANSACTION_FAILURE: {
      return {
        ...state,
        swapnSignageTransaction: {
          errorMessage: action.message,
          data: undefined,
        },
      };
    }

    case RESTORE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        restoreTransaction: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }

    case RESTORE_TRANSACTION_FAILURE: {
      return {
        ...state,
        swapnSignageTransaction: {
          errorMessage: action.message,
          data: undefined,
        },
      };
    }
    default:
      return state;
  }
}
