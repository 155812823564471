import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { StatementTransactionServiceService } from "src/app/service/StatementTransactionService/statement-transaction-service.service";
import { TransactionReviewService } from "src/app/service/TransactionReview/transaction-review.service";
import {
  AddOrUpdateTransactionFlags,
  AddOrUpdateTransactionFlagsFailure,
  AddOrUpdateTransactionFlagsSuccess,
  GetAllStatementTransactionsByLoanId,
  GetAllStatementTransactionsByLoanIdFailure,
  GetAllStatementTransactionsByLoanIdSuccess,
  GetTransactionCategoryData,
  GetTransactionCategoryFailure,
  GetTransactionCategorySuccess,
  GetTransactionFlagsData,
  GetTransactionFlagsFailure,
  GetTransactionFlagsSuccess,
  IncludeExcludeForIncomeCalculation,
  IncludeExcludeForIncomeCalculationFailure,
  IncludeExcludeForIncomeCalculationSuccess,
  ResetIncludeExcludeForIncomeCalculation,
  ResetIncludeExcludeForIncomeCalculationFailure,
  ResetIncludeExcludeForIncomeCalculationSuccess,
  UpdateTransactionCategoryData,
  UpdateTransactionCategoryDataFailure,
  UpdateTransactionCategoryDataSuccess,
} from "./transactionReview.actions";

@Injectable()
export class TransactionReviewEffects {
  constructor(
    private actions$: Actions,
    private transactionReviewService: TransactionReviewService,
    private statementTransactionServiceService: StatementTransactionServiceService
  ) {}

  GetAllStatementTransactionsByLoanId = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllStatementTransactionsByLoanId),
      mergeMap((action) =>
        this.transactionReviewService.getAllStatementTransactionsByLoanId(action.loanId).pipe(
          map((response) => {
            if (response.success) {
              return GetAllStatementTransactionsByLoanIdSuccess({ data: response.data });
            } else {
              return GetAllStatementTransactionsByLoanIdFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(GetAllStatementTransactionsByLoanIdFailure({ message: error.error.message })))
        )
      )
    )
  );

  AddOrUpdateTransactionFlags = createEffect(() =>
    this.actions$.pipe(
      ofType(AddOrUpdateTransactionFlags),
      mergeMap((action) =>
        this.transactionReviewService
          .addOrUpdateTransactionFlags(action.statementTransactionId, action.flagsCode, action.loanId)
          .pipe(
            map((response) => {
              if (response.success) {
                return AddOrUpdateTransactionFlagsSuccess({ data: response.data });
              } else {
                return AddOrUpdateTransactionFlagsFailure({ errors: response.errors, message: response.message });
              }
            }),
            catchError((error) => of(AddOrUpdateTransactionFlagsFailure({ message: error.error.message })))
          )
      )
    )
  );

  UpdateTransactionCategoryData = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateTransactionCategoryData),
      mergeMap((action) =>
        this.transactionReviewService.updateTransactionCategory(action.updatePayload).pipe(
          map((response) => {
            if (response.success) {
              return UpdateTransactionCategoryDataSuccess({ data: response.data });
            } else {
              return UpdateTransactionCategoryDataFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(UpdateTransactionCategoryDataFailure({ message: error.error.message })))
        )
      )
    )
  );

  GetTransactionFlagsData = createEffect(() =>
    this.actions$.pipe(
      ofType(GetTransactionFlagsData),
      mergeMap(() =>
        this.transactionReviewService.getTransactionFlags().pipe(
          map((response) => {
            if (response.success) {
              return GetTransactionFlagsSuccess({ data: response.data });
            } else {
              return GetTransactionFlagsFailure({
                errors: response.errors,
                message: response.message,
              });
            }
          }),
          catchError((error) => of(GetTransactionFlagsFailure({ message: error?.error?.message })))
        )
      )
    )
  );

  GetTransactionCategoryData = createEffect(() =>
    this.actions$.pipe(
      ofType(GetTransactionCategoryData),
      mergeMap(() =>
        this.transactionReviewService.getCategories().pipe(
          map((response) => {
            if (response.success) {
              return GetTransactionCategorySuccess({ data: response.data });
            } else {
              return GetTransactionCategoryFailure({
                errors: response.errors,
                message: response.message,
              });
            }
          }),
          catchError((error) => of(GetTransactionCategoryFailure({ message: error?.error?.message })))
        )
      )
    )
  );

  IncludeExcludeForIncomeCalculationData = createEffect(() =>
    this.actions$.pipe(
      ofType(IncludeExcludeForIncomeCalculation),
      mergeMap((action) =>
        this.statementTransactionServiceService.includeExcludeForIncomeCalculation(action.payload).pipe(
          map((response) => {
            if (response.success) {
              return IncludeExcludeForIncomeCalculationSuccess({ data: response.data });
            } else {
              return IncludeExcludeForIncomeCalculationFailure({
                errors: response.errors,
                message: response.message,
              });
            }
          }),
          catchError((error) => of(IncludeExcludeForIncomeCalculationFailure({ message: error.error.message })))
        )
      )
    )
  );

  ResetIncludeExcludeForIncomeCalculationData = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetIncludeExcludeForIncomeCalculation),
      mergeMap((action) =>
        this.statementTransactionServiceService.resetIncludeExcludeForIncomeCalculation(action.payload).pipe(
          map((response) => {
            if (response.success) {
              return ResetIncludeExcludeForIncomeCalculationSuccess({ data: response.data });
            } else {
              return ResetIncludeExcludeForIncomeCalculationFailure({
                errors: response.errors,
                message: response.message,
              });
            }
          }),
          catchError((error) => of(ResetIncludeExcludeForIncomeCalculationFailure({ message: error.error.message })))
        )
      )
    )
  );
}
