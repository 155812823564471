 
import {
  CLEAR_DOCUMENT_TYPE_ERROR_STATE,
  CLEAR_RULE_STATE,
  GET_ADDED_RULE_FAILURE,
  GET_ADDED_RULE_SUCCESS,
  GET_APPLICABLE_LOAN_TYPES,
  GET_APPLICABLE_LOAN_TYPES_FAILURE,
  GET_APPLICABLE_LOAN_TYPES_SUCCESS,
  GET_DOCUMENT_TYPES,
  GET_DOCUMENT_TYPES_FAILURE,
  GET_DOCUMENT_TYPES_SUCCESS,
  GET_DOCUMENT_VERSION_FIELD_LIST,
  GET_DOCUMENT_VERSION_FIELD_LIST_FAILURE,
  GET_DOCUMENT_VERSION_FIELD_LIST_SUCCESS,
  GET_LOS_FIELD_LIST_FAILURE,
  GET_LOS_FIELD_LIST_SUCCESS,
  GET_PROJECTS,
  GET_PROJECTS_FAILURE,
  GET_PROJECTS_SUCCESS,
  GET_RULE_BY_ID_SUCCESS,
  GET_RULES,
  GET_RULES_FAILURE,
  GET_RULES_SUCCESS,
  GET_UPDATED_RULE_SUCCESS,
  RuleEngineActions,
  UPDATE_DOCUMENT_FIELD_VERSION_LIST_LOOKUP,
  UPDATE_DOCUMENT_TYPES,
  UPDATE_RULE_FILTERS,
} from "./rule-engine.actions";
import { RuleEngineState } from "./rule-engine.types";

const initialState: RuleEngineState = {
  rule: { data: undefined, errorMessage: undefined },
  documentTypeOptions: [],
  documentFieldVersionListLookUp: {},
  LOSFieldLost: [],
  ruleFilters: undefined,
  rulesList: { data: [], isFreshFetch: true },
  isLoading: 0,
  applicableLoanTypes: {data: [], hasError: false },
  documentTypes: { data: [], hasError: false },
  projects: [],
  documentVersionFieldList: { data: [], hasError: false },
};

export function ruleEngineReducer(state = initialState, action: RuleEngineActions): RuleEngineState {
  switch (action.type) {
    case GET_RULE_BY_ID_SUCCESS:
      return {
        ...state,
        rule: {
          data: action.rules[0],
          errorMessage: undefined,
        },
      };

    case GET_ADDED_RULE_SUCCESS:
      return {
        ...state,
        rule: {
          data: action.rule,
          errorMessage: undefined,
        },
      };
    
    case GET_ADDED_RULE_FAILURE:
      return {
        ...state,
        rule: {
          data: action.rule,
          errorMessage: action.errorMessage,
        }
      }

    case GET_UPDATED_RULE_SUCCESS:
      return {
        ...state,
        rule: {
          data: action.rule,
          errorMessage: undefined,
        },
      };

    case CLEAR_RULE_STATE:
      return {
        ...state,
        rule: {
          data: undefined,
          errorMessage: undefined,
        },
      };

    case UPDATE_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypeOptions: action.documentTypes,
      };

    case UPDATE_DOCUMENT_FIELD_VERSION_LIST_LOOKUP:
      return {
        ...state,
        documentFieldVersionListLookUp: action.documentFieldVersionListLookUp,
      };

    case GET_LOS_FIELD_LIST_SUCCESS:
      return {
        ...state,
        LOSFieldLost: action.LOSFieldList,
      };

    case GET_LOS_FIELD_LIST_FAILURE:
      return {
        ...state,
        LOSFieldLost: [],
      };

    case UPDATE_RULE_FILTERS:
      return {
        ...state,
        ruleFilters: action.ruleFilters,
      };

    case GET_RULES:
      return {
        ...state,
        rulesList: { data: [], isFreshFetch: action.isFreshFetch },
        isLoading: state.isLoading + 1,
      };

    case GET_RULES_SUCCESS:
      return {
        ...state,
        rulesList: { data: action.rulesList, isFreshFetch: action.isFreshFetch },
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };

    case GET_RULES_FAILURE:
      return {
        ...state,
        rulesList: { data: [], isFreshFetch: action.isFreshFetch },
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };

    case GET_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: { data: [], hasError: false },
        isLoading: state.isLoading + 1,
      };

    case GET_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        documentTypes: { data: action.documentTypes, hasError: false },
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };

    case GET_DOCUMENT_TYPES_FAILURE:
      return {
        ...state,
        documentTypes: { data: [], hasError: true },
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };

    case GET_APPLICABLE_LOAN_TYPES:
      return {
        ...state,
        applicableLoanTypes: { data: [], hasError: false },
        isLoading: state.isLoading + 1,
      };

    case GET_APPLICABLE_LOAN_TYPES_SUCCESS:
      return {
        ...state,
        applicableLoanTypes: { data: action.applicableLoanTypes, hasError: false },
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };

    case GET_APPLICABLE_LOAN_TYPES_FAILURE:
      return {
        ...state,
        applicableLoanTypes: { data: [], hasError: true },
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };
    
    case GET_PROJECTS:
      return {
        ...state,
        isLoading: state.isLoading + 1,
      };

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.projects,
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };

    case GET_PROJECTS_FAILURE:
      return {
        ...state,
        projects: [],
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };
    
    case GET_DOCUMENT_VERSION_FIELD_LIST:
      return {
        ...state,
        documentVersionFieldList: { data: [], hasError: false },
        isLoading: state.isLoading + 1,
      };

    case GET_DOCUMENT_VERSION_FIELD_LIST_SUCCESS:
      return {
        ...state,
        documentVersionFieldList: { data: action.documentVersionFieldList, hasError: false },
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };

    case GET_DOCUMENT_VERSION_FIELD_LIST_FAILURE:
      return {
        ...state,
        documentVersionFieldList: { data: [], hasError: true },
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };

    case CLEAR_DOCUMENT_TYPE_ERROR_STATE:
      return {
        ...state,
        documentTypes: {
          ...state.documentTypes,
          hasError: false,
        },
        applicableLoanTypes: {
          ...state.applicableLoanTypes,
          hasError: false,
        },
        documentVersionFieldList: {
          ...state.documentVersionFieldList,
          hasError: false,
        }
      }
    default:
      return state;
  }
}
