import {
  ADD_OR_UPDATE_TRANSACTION_FLAGS,
  ADD_OR_UPDATE_TRANSACTION_FLAGS_FAILURE,
  ADD_OR_UPDATE_TRANSACTION_FLAGS_SUCCESS,
  GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID,
  GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID_FAILURE,
  GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID_SUCCESS,
  GET_TRANSACTION_CATEGORY,
  GET_TRANSACTION_CATEGORY_FAILURE,
  GET_TRANSACTION_CATEGORY_SUCCESS,
  GET_TRANSACTION_FLAG,
  GET_TRANSACTION_FLAG_FAILURE,
  GET_TRANSACTION_FLAG_SUCCESS,
  INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION,
  INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_FAILURE,
  INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_SUCCESS,
  RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION,
  RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_FAILURE,
  RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_SUCCESS,
  RESET_TRANSACTION_DETAILS,
  TransactionReviewActions,
  UPDATE_TRANSACTION_CATEGORY,
  UPDATE_TRANSACTION_CATEGORY_FAILURE,
  UPDATE_TRANSACTION_CATEGORY_SUCCESS,
} from "./transactionReview.actions";
import { TransactionReviewState } from "./transactionReview.types";

const initialState: TransactionReviewState = {
  transactionDetails: { data: [], errorMessage: undefined },
  transactionFlagsForGridData: { data: [], errorMessage: undefined },
  transactionCategoryForGridData: { data: [], errorMessage: undefined },
};

export function transactionReviewReducer(
  state = initialState,
  action: TransactionReviewActions
): TransactionReviewState {
  switch (action.type) {
    case GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID:
      return state;

    case GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID_SUCCESS: {
      return {
        ...state,
        transactionDetails: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID_FAILURE: {
      return {
        ...state,
        transactionDetails: {
          errorMessage: action.message,
          data: [],
        },
      };
    }

    case RESET_TRANSACTION_DETAILS: {
      return {
        ...state,
        transactionDetails: {
          errorMessage: undefined,
          data: [],
        },
      };
    }

    case ADD_OR_UPDATE_TRANSACTION_FLAGS:
      return state;

    case ADD_OR_UPDATE_TRANSACTION_FLAGS_SUCCESS: {
      return {
        ...state,
        transactionDetails: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case ADD_OR_UPDATE_TRANSACTION_FLAGS_FAILURE: {
      return {
        ...state,
        transactionDetails: {
          errorMessage: action.message,
          data: [],
        },
      };
    }

    case UPDATE_TRANSACTION_CATEGORY:
      return state;

    case UPDATE_TRANSACTION_CATEGORY_SUCCESS: {
      return {
        ...state,
        transactionDetails: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case UPDATE_TRANSACTION_CATEGORY_FAILURE: {
      return {
        ...state,
        transactionDetails: {
          errorMessage: action.message,
          data: [],
        },
      };
    }

    case GET_TRANSACTION_FLAG:
      return state;

    case GET_TRANSACTION_FLAG_SUCCESS: {
      return {
        ...state,
        transactionFlagsForGridData: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_TRANSACTION_FLAG_FAILURE: {
      return {
        ...state,
        transactionFlagsForGridData: {
          errorMessage: action.message,
          data: [],
        },
      };
    }

    case GET_TRANSACTION_CATEGORY:
      return state;

    case GET_TRANSACTION_CATEGORY_SUCCESS: {
      return {
        ...state,
        transactionCategoryForGridData: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_TRANSACTION_CATEGORY_FAILURE: {
      return {
        ...state,
        transactionCategoryForGridData: {
          errorMessage: action.message,
          data: [],
        },
      };
    }

    case INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION:
      return state;

    case INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_SUCCESS: {
      return {
        ...state,
        transactionDetails: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_FAILURE: {
      return {
        ...state,
        transactionDetails: {
          errorMessage: action.message,
          data: [],
        },
      };
    }

    case RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION:
      return state;

    case RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_SUCCESS: {
      return {
        ...state,
        transactionDetails: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_FAILURE: {
      return {
        ...state,
        transactionDetails: {
          errorMessage: action.message,
          data: [],
        },
      };
    }

    default:
      return state;
  }
}
