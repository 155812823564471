/* eslint-disable */
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ToastrService } from "ngx-toastr";
import { catchError, map, mergeMap, of } from "rxjs";
import { RuleEngineService } from "src/app/service/RuleEngineService/rule-engine.service";
import {
  AddRule,
  GetAddedRuleFailure,
  GetAddedRuleSuccess,
  getLOSFieldList,
  getLOSFieldListSuccess,
  GetRuleById,
  GetRuleByIdSuccess,
  GetUpdatedRuleSuccess,
  UpdateRule,
} from "./rule-engine.actions";

@Injectable()
export class RuleEngineEffects {
  constructor(
    private actions$: Actions,
    private ruleEngineService: RuleEngineService,
    private toaster: ToastrService,
  ) {}

  AddRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddRule),
      mergeMap((action) =>
        this.ruleEngineService.createRule(action.rule).pipe(
          map((response) => {
            this.toaster.success(
              "Rule Created Successfully.",
              'Success',
              {
                timeOut: 2000,
                progressBar: true,
                positionClass: 'toast-top-center'
              }
            );
            return GetAddedRuleSuccess({ rule: response });
          }),
          catchError((error) => {
            this.toaster.error(
              "Error Creating Rule",
              'Error',
              {
                timeOut: 2000,
                progressBar: true,
                positionClass: 'toast-top-center'
              }
            );
            return of(GetAddedRuleFailure({ rule: undefined, errorMessage: "Error creating rule" }));
          })
        )
      )
    )
  );

  UpdateRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateRule),
      mergeMap((action) =>
        this.ruleEngineService.updateRule(action.ruleId, action.rule).pipe(
          map((response) => {
            return GetUpdatedRuleSuccess({ rule: {...response, id: parseInt(action.ruleId)} });
          })
        )
      )
    )
  );

  GetRuleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetRuleById),
      mergeMap((action) =>
        this.ruleEngineService.getRuleById(action.ruleId, action.projectId).pipe(
          map((response) => {
            return GetRuleByIdSuccess({ rules: response.rules });
          })
        )
      )
    )
  );

  GetLOSFieldList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLOSFieldList),
      mergeMap((action) =>
        this.ruleEngineService.getLosFieldList().pipe(
          map((response) => {
            if (response.success) {
              return getLOSFieldListSuccess({ LOSFieldList: response.data });
            } else {
              return getLOSFieldListSuccess({ LOSFieldList: [] });
            }
          })
        )
      )
    )
  );
}
