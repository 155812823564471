import { Component, OnDestroy, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { BnNgIdleService } from "bn-ng-idle";
import { filter, Subject, takeUntil } from "rxjs";
import { CustomerDetailsService } from "./service/CustomerDetails/customer-details.service";
import { TokenStorageService } from "./service/TokenStorageService/token-storage.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  isSessionExpired = false;
  isLoggedIn = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private idleService: BnNgIdleService,
    private msalService: MsalService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private tokenStorageService: TokenStorageService,
    private customerDetailsService: CustomerDetailsService
  ) {
    this.idleService.startWatching(1200).subscribe((isExpired) => {
      if (isExpired) {
        this.isSessionExpired = true;
        this.tokenStorageService.clearStorage();
        this.router.navigate(["/"], { replaceUrl: true });

        setTimeout(() => {
          window.history.pushState(null, "", "/");
        }, 0);
      }
    });
  }

  ngOnInit(): void {
    localStorage.removeItem("isTransactionReviewScreenActive");
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status) => status === InteractionStatus.None),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.updateLoginStatus();
        this.ensureActiveAccount();
      });
  }

  updateLoginStatus(): void {
    this.isLoggedIn = this.msalService.instance.getAllAccounts().length > 0;
    localStorage.setItem("isUserLoggedIn", String(this.isLoggedIn));

    if (!this.isLoggedIn) {
      console.log("Forcing user to login");
      this.msalService.loginRedirect();
    }
  }

  ensureActiveAccount(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      const firstAccount: AccountInfo = accounts[0];
      this.msalService.instance.setActiveAccount(firstAccount);
      this.tokenStorageService.setAuthLoginData(firstAccount);
      this.customerDetailsService.getUserContextUpdate().subscribe({
        next: (response) => {
          console.log("User context updated:", response);
        },
        error: (err) => {
          console.error("Error updating user context:", err);
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
