import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { catchError, map, mergeMap, of, switchMap, tap } from "rxjs";
import { DataExtractionHILService } from "src/app/service/DataExtractionHILService/data-extraction-hil.service";
import {
  addLoanDataExtraction,
  addLoanDataExtractionFailure,
  addLoanDataExtractionSuccess,
  DeleteRestoreFieldValue,
  DeleteRestoreFieldValueFailure,
  DeleteRestoreFieldValueSuccess,
  fetchLoanFields,
  fetchLoanFieldsFailure,
  fetchLoanFieldsSuccess,
  GetDocumentByDocumentId,
  GetDocumentByDocumentIdFailure,
  GetDocumentByDocumentIdSuccess,
  getDocuments,
  getDocumentsFailure,
  getDocumentsSuccess,
  getEntityList,
  getEntityListFailure,
  getEntityListSuccess,
  loadDataExtractionHIL,
  loadDataExtractionHILFailure,
  loadDataExtractionHILSuccess,
  revertToOriginalFieldData,
  revertToOriginalFieldDataFailure,
  revertToOriginalFieldDataSuccess,
  UpdateIsTruth,
  UpdateIsTruthFailure,
  UpdateIsTruthSuccess,
  updateLoanDataExtraction,
  updateLoanDataExtractionFailure,
  updateLoanDataExtractionSuccess,
  updateTheFieldData,
  updateTheFieldDataFailure,
  updateTheFieldDataSuccess,
} from "./data-extraction-hil.actions";
import { getDocumentByIdSelector } from "./data-extraction-hil.selectors";

@Injectable()
export class DataExtractionHILEffects {
  constructor(
    private actions$: Actions,
    private toaster: ToastrService,
    private dataExtractionHILService: DataExtractionHILService,
    private store: Store
  ) {}

  loadDataExtractionHIL$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDataExtractionHIL),
      switchMap(({ projectId, loanId }) =>
        this.dataExtractionHILService.getDataExtractionByLoanId(projectId, loanId).pipe(
          map((loandata) => {
            return loadDataExtractionHILSuccess({ data: loandata.data });
          }),
          catchError((error) => {
            console.error("Error Loading Data Extraction:", error);
            return of(loadDataExtractionHILFailure({ error: error.message }));
          })
        )
      )
    )
  );

  addLoanDataExtraction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addLoanDataExtraction),
      switchMap(({ projectId, loanId, addLoanDataExtraction }) =>
        this.dataExtractionHILService.addLoanExtraction(projectId, loanId, addLoanDataExtraction).pipe(
          map((response) => addLoanDataExtractionSuccess({ success: response.data })),
          catchError((error) =>
            of(addLoanDataExtractionFailure({ error: error.message || "Failed to add loan extraction" }))
          )
        )
      )
    )
  );

  showSuccessMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addLoanDataExtractionSuccess),
        tap(() => {
          this.toaster.success("Data Created Successfully.", "Success", {
            timeOut: 2000,
            progressBar: true,
            positionClass: "toast-top-center",
          });
        })
      ),
    { dispatch: false }
  );

  showErrorMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addLoanDataExtractionFailure),
        tap(() => {
          this.toaster.error("Unable to save, please try again later", "Error", {
            timeOut: 2000,
            progressBar: true,
            positionClass: "toast-top-center",
          });
        })
      ),
    { dispatch: false }
  );

  updateLoanDataExtraction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLoanDataExtraction),
      switchMap((action) => {
        const { projectId, loanId, fieldId, updateLoanDataExtraction } = action;

        return this.dataExtractionHILService
          .updateDataExtraction(loanId, projectId, fieldId, updateLoanDataExtraction)
          .pipe(
            map((response) => {
              return updateLoanDataExtractionSuccess({ success: response.data });
            }),
            catchError((error) => {
              return of(
                updateLoanDataExtractionFailure({
                  error: error.message || "Failed to update loan extraction",
                })
              );
            })
          );
      })
    )
  );

  fetchLoanFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLoanFields),
      switchMap((action) => {
        return this.dataExtractionHILService.fetchFields(action.projectId, action.loanId, action.fetchFields).pipe(
          map((response) => {
            return fetchLoanFieldsSuccess({ response: response.data });
          }),
          catchError((error) => {
            return of(
              fetchLoanFieldsFailure({
                error: error.message || "Failed to fetch loan field extraction",
              })
            );
          })
        );
      })
    )
  );

  fetchEntityList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityList),
      switchMap((action) => {
        return this.dataExtractionHILService.fetchEntity(action.projectId, action.loanId, action.fieldId).pipe(
          map((response) => {
            return getEntityListSuccess({ response: response.data });
          }),
          catchError((error) => {
            return of(
              getEntityListFailure({
                error: error.message || "Failed to entity extraction",
              })
            );
          })
        );
      })
    )
  );

  getDocumentData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDocuments),
      switchMap(({ projectId, loanId, fieldId, requestBody }) =>
        this.dataExtractionHILService.getDocumentData(projectId, loanId, fieldId, requestBody).pipe(
          map((response) => getDocumentsSuccess({ response: response.data })),
          catchError((error: HttpErrorResponse) =>
            of(
              getDocumentsFailure({
                error: error.message || "Failed to fetch document data.",
              })
            )
          )
        )
      )
    )
  );

  restoreFieldData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRestoreFieldValue),
      switchMap(({ projectId, loanId, fieldValueId, deleteRestoreFlag }) =>
        this.dataExtractionHILService.deleteRestoreFieldValue(projectId, loanId, fieldValueId, deleteRestoreFlag).pipe(
          map((response) => DeleteRestoreFieldValueSuccess({ success: response.data })),
          catchError((error) => of(DeleteRestoreFieldValueFailure({ error: error.message })))
        )
      )
    )
  );

  GetDocumentByDocumentId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetDocumentByDocumentId),
      mergeMap((action) =>
        this.store.select(getDocumentByIdSelector(action.documentId)).pipe(
          mergeMap((existingDocument) => {
            if (existingDocument && !action.freshfetch) {
              return of(
                GetDocumentByDocumentIdSuccess({
                  documentId: action.documentId,
                  data: existingDocument,
                })
              );
            } else {
              return this.dataExtractionHILService
                .getDocumentByDocId(action.documentId, action.projectId, action.loanId)
                .pipe(
                  map((response) => {
                    if (response.success) {
                      return GetDocumentByDocumentIdSuccess({
                        data: response.data,
                        documentId: action.documentId,
                      });
                    } else {
                      return GetDocumentByDocumentIdFailure({
                        documentId: action.documentId,
                        message: response.message,
                        errors: response.errors,
                      });
                    }
                  }),
                  catchError(() =>
                    of(
                      GetDocumentByDocumentIdFailure({
                        documentId: action.documentId,
                        message: "An error occurred while fetching the document",
                      })
                    )
                  )
                );
            }
          })
        )
      )
    )
  );

  updateIsTruth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateIsTruth),
      switchMap((action) => {
        const { fieldValueId, projectId, loanId } = action;

        return this.dataExtractionHILService.setSourceOfTruth(fieldValueId, projectId, loanId).pipe(
          map((response) => {
            return UpdateIsTruthSuccess({ success: response.success });
          }),
          catchError((error) => {
            return of(
              UpdateIsTruthFailure({
                error: error.message || "Failed to set the source as truth.",
              })
            );
          })
        );
      })
    )
  );

  updateTheFieldData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTheFieldData),
      switchMap((action) => {
        const { projectId, loanId, fieldValueId, updateTheFieldValue } = action;

        const fieldValue = updateTheFieldValue ?? "";

        return this.dataExtractionHILService.updateTheFieldData(projectId, loanId, fieldValueId, fieldValue).pipe(
          map((response) => {
            return updateTheFieldDataSuccess({ success: response.success });
          }),
          catchError((error) => {
            return of(
              updateTheFieldDataFailure({
                error: error.message || "Error updating the field. Please try again.",
              })
            );
          })
        );
      })
    )
  );

  revertToOriginalFieldData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(revertToOriginalFieldData),
      switchMap((action) => {
        const { projectId, loanId, fieldValueId } = action;

        return this.dataExtractionHILService.revertToOriginalFieldData(projectId, loanId, fieldValueId).pipe(
          map((response) => {
            return revertToOriginalFieldDataSuccess({ success: response.success });
          }),
          catchError((error) => {
            return of(
              revertToOriginalFieldDataFailure({
                error: error.message || "Failed to revert the original field value.",
              })
            );
          })
        );
      })
    )
  );
}
