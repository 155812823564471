import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { forkJoin, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { DataExtractionService } from "src/app/service/DataExtractionService/data-extraction.service";
import {
  FetchFileById,
  FetchFileByIdFailure,
  FetchFileByIdSuccess,
  FetchMultipleFiles,
  FetchMultipleFilesFailure,
  FetchMultipleFilesSuccess,
  GetConditions,
  GetConditionsFailure,
  GetConditionsSuccess,
  GetDocumentTypeList,
  GetDocumentTypeListFailure,
  GetDocumentTypeListSuccess,
} from "./data-extraction.actions";

@Injectable()
export class DataExtractionEffects {
  constructor(
    private actions$: Actions,
    private dataExtractionService: DataExtractionService
  ) {}
  // Effect for loading document type list
  loadDocumentTypeList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetDocumentTypeList),
      mergeMap((action) =>
        this.dataExtractionService.getDocumentTypeList(action.loanId,action.projectId).pipe(
          map((response) => {
            if (response.success) {
              return GetDocumentTypeListSuccess({ data: response.data });
            } else {
              return GetDocumentTypeListFailure({
                errors: response.errors,
                message: response.message,
              });
            }
          }),
          catchError((error) =>
            of(
              GetDocumentTypeListFailure({
                errors: error,
                message: error.message || "Failed to load document types",
              })
            )
          )
        )
      )
    )
  );

  loadConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetConditions),
      mergeMap((action) =>
        this.dataExtractionService.getConditionsForLoanId(action.loanId,action.projectId).pipe(
          map((response) => {
            if (response.success) {
              return GetConditionsSuccess({ data: response.data, message: response.message });
            } else {
              return GetConditionsFailure({
                errors: response.errors,
                message: response.message,
              });
            }
          }),
          catchError((error) =>
            of(
              GetConditionsFailure({
                errors: error,
                message: error.message || "Failed to load conditions",
              })
            )
          )
        )
      )
    )
  );

  fetchFileById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FetchFileById),
      mergeMap((action) =>
        this.dataExtractionService.fetchFileById(action.loanId, action.fileId,action.projectId).pipe(
          map((response) => {
            if (response.success) {
              return FetchFileByIdSuccess({ data: response.data, fileId: action.fileId });
            } else {
              return FetchFileByIdFailure({
                message: response.message,
              });
            }
          }),
          catchError((error) =>
            of(
              FetchFileByIdFailure({
                message: error.message || "Failed to fetch file by ID",
              })
            )
          )
        )
      )
    )
  );

  fetchFileMultipleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FetchMultipleFiles),
      mergeMap(({ fileIds, loanId , projectId }) => {
        const fileRequests = fileIds.map((fileId) =>
          this.dataExtractionService.fetchFileById(loanId, fileId,projectId).pipe(
            map((response) => {
              if (response.success) {
                return { fileId, base64: response.data }; 
              } else {
                throw new Error(response.message || "Failed to fetch file");
              }
            }),
            catchError((error) =>
              of({
                fileId,
                error: error.message || "Failed to fetch file",
              })
            )
          )
        );

        return forkJoin(fileRequests).pipe(
          map((results) => {
            const successResults: { fileId: string; base64: string }[] = results.filter(
              (result): result is { fileId: string; base64: string } => "base64" in result
            );
            const errorResults: { fileId: string; error: string }[] = results.filter(
              (result): result is { fileId: string; error: string } => "error" in result
            );

            return FetchMultipleFilesSuccess({
              files: successResults,
              errors: errorResults,
            });
          }),
          catchError((error) =>
            of(
              FetchMultipleFilesFailure({
                message: error.message || "Failed to fetch multiple files",
              })
            )
          )
        );
      })
    )
  );
}
